import { linkBridge } from "@webview-bridge/web";

import { AppBridge, AppPostMessageSchema } from "./bridge.output";

const bridge = linkBridge<AppBridge, AppPostMessageSchema>({
  onReady: async (method) => {
    console.log("bridge is ready");
  },
});

function useNativeAppBridge() {
  return bridge;
}

export default useNativeAppBridge;
